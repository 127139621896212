.accred-block
{
    display: grid;
    grid-gap: 2rem;
    grid-auto-flow: row;
    align-items: center;

    @include media-breakpoint-down(md)
    {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: repeat(2, min-content);
    }

    img
    {
        width: 100%;
        height: 110px;
        object-fit: contain;
        object-position: center center;
    }

    > .accred-block-row
    {
        display: grid;
        align-items: center;
        gap: 1rem .5rem;
        grid-template-columns: 182fr 359fr 353fr;

        @include media-breakpoint-down(md)
        {
            grid-template-columns: 182fr 359fr;
            grid-template-rows: 1fr 1fr;

            > .mob-row-3
            {
                grid-column: span 2;
            }
        }

        @include media-breakpoint-down(sm)
        {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        > *
        {
            width: auto;
            flex: 0 0 auto;
            height: 100px;
            display: flex;
            gap: 1rem;
            justify-content: space-around;

            > img
            {
                height: 100%;
                flex: 0 1 auto;
                width: auto !important;
                min-width: 0;
            }

            @include media-breakpoint-only(lg)
            {
                height: 72px;
            }

            @include media-breakpoint-down(md)
            {
                width: 100%;
                height: auto;

                &.mob-row-1 > img
                {
                    max-height: 90px;
                }

                &.mob-row-2 > img
                {
                    max-height: 90px;
                }

                &.mob-row-3 > img
                {
                    max-height: 70px;
                }
            }
        }
    }

    #sra-badge
    {
        flex: 0 0 170px;
        max-width: 170px !important;
        margin: 0 !important;

        @include media-breakpoint-only(lg)
        {
            flex: 0 0 122px;
            max-width: 122px !important;
        }

        @include media-breakpoint-down(md)
        {
            flex: 0 0 116px;
            max-width: 116px !important;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }

    > .accred-block-law
    {
        display: flex;

        @include media-breakpoint-only(lg)
        {
            padding-left: 1rem;

            img
            {
                height: 80px;
            }
        }

        @include media-breakpoint-down(md)
        {
            display: grid;
            grid-gap: 1.5rem;
            grid-template-columns: 1fr 1fr 1fr;

            img
            {
                height: 60px;
            }
        }
    }
}
